<template>
  <div class="invited-member-table">
    <SectionTitle hideBtn style="margin-bottom: 0">
      <template slot="title">
        <p style="font-size: 18px">推薦會員清單</p>
        <p class="text-gray-60 text-sub" style="margin-left: 12px">( 表單資料為「透過推薦連結或推薦碼註冊」之所有會員 )</p>
      </template>
    </SectionTitle>

    <FiltersContainer>
      <MemberSearch :model.sync="search.member" @change="refresh" @clear="refresh(true)" />
    </FiltersContainer>

    <BaseTable v-loading="loading.table" :data="displayData">
      <EmptyBlock slot="empty" />
      <BaseElTableColumn label="會員姓名" prop="name" align="center" />
      <BaseElTableColumn label="電話號碼" prop="phone" align="center" />
      <BaseElTableColumn label="推薦人" prop="inviter" align="center" />
      <BaseElTableColumn label="Line 帳號綁定時間" prop="bindAt" align="center" />
      <BaseElTableColumn label="操作" fixed="right" width="110" align="center">
        <template slot-scope="scope">
          <router-link
            class="underline"
            :to="{
              name: 'MemberProfile',
              params: {
                id: scope.row.memberId
              },
              query: {
                openInviteRecord: true
              },
            }"
          >
            查看
          </router-link>
        </template>
      </BaseElTableColumn>
    </BaseTable>

    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(false)"
    />
  </div>
</template>

<script>
import { defineComponent, computed, ref, onMounted, reactive } from 'vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import FiltersContainer from '@/components/Container/FiltersContainer.vue'
import MemberSearch from '@/components/Search/MemberSearch.vue'
import { useTable } from '@/use/table'
import { get, map } from 'lodash'
import { GetMemberInviteReportRecord, GetMemberInviteReportRecordCount } from '@/api/memberInvite'
import { formatDate, isInvalidDate } from '@/utils/date'

export default defineComponent({
  name: 'InvitedMemberTable',
  components: { EmptyBlock, FiltersContainer, MemberSearch },
  emits: ['refresh'],
  setup (props, { emit }) {
    const { loading, shopId, tableData, fetchData, fetchDataCount, tableDataCount, tableOptions, pageStartIndex } = useTable()
    const search = reactive({
      member: null,
    })

    const displayData = computed(() => {
      return map(tableData.value, (i) => {
        const successAt = formatDate(get(i, 'successAt'))
        return {
          name: get(i, 'Referee.UserInfo.name'),
          phone: get(i, 'Referee.UserInfo.phone'),
          memberId: get(i, 'Referee.id'),
          inviter: get(i, 'Referrer.UserInfo.name'),
          bindAt: isInvalidDate(successAt) ? '尚未完成綁定' : successAt,
        }
      })
    })

    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        RefereeId: get(search.member, 'id'),
      }
      await Promise.allSettled([
        fetchData(GetMemberInviteReportRecord, payload),
        fetchDataCount(GetMemberInviteReportRecordCount, payload),
      ])
    }

    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await getTableData()
      loading.table = false
    }

    onMounted(() => {
      refresh()
    })

    return {
      loading,
      refresh,
      search,
      tableOptions,
      displayData,
      tableData,
      tableDataCount,
    }
  },
})
</script>
