var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"invited-member-table"},[_c('SectionTitle',{staticStyle:{"margin-bottom":"0"},attrs:{"hideBtn":""}},[_c('template',{slot:"title"},[_c('p',{staticStyle:{"font-size":"18px"}},[_vm._v("推薦會員清單")]),_c('p',{staticClass:"text-gray-60 text-sub",staticStyle:{"margin-left":"12px"}},[_vm._v("( 表單資料為「透過推薦連結或推薦碼註冊」之所有會員 )")])])],2),_c('FiltersContainer',[_c('MemberSearch',{attrs:{"model":_vm.search.member},on:{"update:model":function($event){return _vm.$set(_vm.search, "member", $event)},"change":_vm.refresh,"clear":function($event){return _vm.refresh(true)}}})],1),_c('BaseTable',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading.table),expression:"loading.table"}],attrs:{"data":_vm.displayData}},[_c('EmptyBlock',{attrs:{"slot":"empty"},slot:"empty"}),_c('BaseElTableColumn',{attrs:{"label":"會員姓名","prop":"name","align":"center"}}),_c('BaseElTableColumn',{attrs:{"label":"電話號碼","prop":"phone","align":"center"}}),_c('BaseElTableColumn',{attrs:{"label":"推薦人","prop":"inviter","align":"center"}}),_c('BaseElTableColumn',{attrs:{"label":"Line 帳號綁定時間","prop":"bindAt","align":"center"}}),_c('BaseElTableColumn',{attrs:{"label":"操作","fixed":"right","width":"110","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{staticClass:"underline",attrs:{"to":{
            name: 'MemberProfile',
            params: {
              id: scope.row.memberId
            },
            query: {
              openInviteRecord: true
            },
          }}},[_vm._v(" 查看 ")])]}}])})],1),_c('Pagination',{attrs:{"curPage":_vm.tableOptions.page,"pageLimit":_vm.tableOptions.pageLimit,"total":_vm.tableDataCount},on:{"update:curPage":function($event){return _vm.$set(_vm.tableOptions, "page", $event)},"update:cur-page":function($event){return _vm.$set(_vm.tableOptions, "page", $event)},"pageChange":function($event){return _vm.refresh(false)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }